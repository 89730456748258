import React, {useState, useEffect} from 'react'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Slider from "react-slick";
import { Link as RouterLink } from 'react-router-dom';
import Wave from 'react-wavify';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { Button, CardActions } from '@mui/material';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ListItemButton from '@mui/material/ListItemButton';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SavingsIcon from '@mui/icons-material/Savings';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import apiClient from "../../request/http-common";
import { useMutation } from "react-query";
import { useNavigate } from 'react-router-dom';
import { setInvestments } from '../../request/Analysis/hitorySlice';
import { useDispatch } from 'react-redux';
import { Currency } from '@depay/local-currency';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';


import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RealEstatePlan = () => {

  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const navigate = useNavigate();
  const plans = useSelector((state) => state.plans.realestatePlans);
  const user = useSelector((state) => state.user.user);
  
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

  const dispatch = useDispatch();
  const [rate, setRate] = useState(1);

  const callRate = async()=> {
    if(user.currency === 'USD'){
      setRate(1);
    }else{
      await Currency.fromUSD({ amount: 1 }).then(amount=>{
        setRate(amount.amount);
       });
    }  
};


    const setcategories = (number_of_downlines, total_earning, initial_earning) => {
        const categories = [];
        for (let i = 0; i <= parseInt(number_of_downlines); i++) {
            let heads = '';
            for (let j = 1; j <= i; j++) {
                heads += '🙂';
            }

            if (i === 0) {
              const initialPercentage = (parseInt(initial_earning) / parseInt(total_earning))*100;
                categories.push('INITIAL ROI = '+initialPercentage.toFixed(2)+'%');
            }else if (i === parseInt(number_of_downlines)) {
                categories.push('STAGE '+i +' = ' + heads+ '' );
            } else {
                categories.push('STAGE '+i +' = ' + heads);
            }
            
        }
        return categories;
    }

    const setAmounts = (initial_earning, total_earning, number_of_downlines, bonus_per_downline, amount_invested) => {

      const initialPercentage = (parseInt(initial_earning) / parseInt(total_earning))*100;
      const amounts = [initialPercentage.toFixed(2)];
      
      for (let downlines = 1; downlines <= parseInt(number_of_downlines); downlines++) {
        amounts.push(parseInt(bonus_per_downline * downlines))
      }
        

        return amounts;

    }

    const [planId, setPlanId] = useState();
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [convertedAmount, setConvertedAmount] = useState(0);

    const { isLoading: isInvesting, mutate: postInvestment } = useMutation(
        async () => {
          return await apiClient.post(`/api/invest`, {
            investment_plan_id: planId,
            amount: amount, 
            rate: rate,
            source: 'real estate'
          });
        },
        {
          onSuccess: (res) => {
              if (res.data.status === 'success') {
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
                dispatch(setInvestments(res.data.investments));
                setTimeout(() => {
                  navigate('/investments');
                }, 2000);
              }            
          },
          onError: (err) => {
                let errorMessage = err.response?.data.message || err ;
                localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
          },
        }
      );

      
      useEffect(() => {
        callRate();
      }, []);

  return (
    <div>
    <Typography mb={-8} mt={4} px={3} sx={{ marginTop: '75px', fontWeight: 'bold', fontSize: 20, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
        REAL ESTATE INVESTMENT PLAN
    </Typography>
    <Typography mb={1}  mt={8} px={3} sx={{  textAlign: 'center', fontSize: 14  }} variant="subtitle1" gutterBottom>
        Kindly choose from any of the plans below to get started
      </Typography>
      {plans.length > 0 &&
        <Grid container px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {plans.map(plan =>(
          <Grid key={plan.id} item xs={12} md={4}>
              <Card  sx={{marginTop: '0px', paddingX: '2px', marginBottom: '0px'}} elevation={2}>
             
              <CardContent sx={{marginBottom: '-30px'}}>
              <Typography gutterBottom variant="h5" component="div">
              {plan.name}
              </Typography>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <ReactApexChart options={{
                chart: {
                  type: 'pie',
                },
                colors: ['#007b3e', '#ff6600'],
                legend: {
                  show: false,
                  showForSingleSeries: false,
                  showForNullSeries: false,
                  showForZeroSeries: false,
                  position: 'middle'
                },
                
                labels: ['ROI', ''],
                dataLabels: {
                  offsetX: 0,
                  offsetY: 0,
                  textAnchor: 'start',
                  formatter(val, opts) {
                    const name = opts.w.globals.labels[opts.seriesIndex]
                    if (val == parseFloat(plan.total_earning)) {
                      return [name, val.toFixed(1) + '%']
                    }else{
                      return ['']
                    }
                    
                  },
                  
                },
                plotOptions: {
                  pie: {
                    dataLabels: {
                      offset: -20
                    }
                  }
                }
                ,
                responsive: [{
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200
                    },
                    
                  }
                }]
              }}
              series={[parseFloat(plan.total_earning), parseFloat(100 - plan.total_earning)]}
              type="pie" height={180}
              />
              </Box>
                  <List>
                      <ListItem disablePadding>
                          <ListItemButton>
                          <ListItemIcon>
                              <SavingsIcon />
                          </ListItemIcon>
                          <ListItemText primary={formatPrice(plan.min_amount )} secondary='MINIMUM AMOUNT TO INVEST' />
                          </ListItemButton>
                      </ListItem>
  
                      <ListItem disablePadding>
                          <ListItemButton>
                          <ListItemIcon>
                              <SavingsIcon />
                          </ListItemIcon>
                          <ListItemText primary={formatPrice(plan.amount_invested )} secondary='MAXIMUM AMOUNT TO INVEST' />
                          </ListItemButton>
                      </ListItem>
  
                      <ListItem disablePadding>
                          <ListItemButton>
                          <ListItemIcon>
                              <AttachMoneyIcon />
                          </ListItemIcon>
                          <ListItemText primary={`${plan.total_earning}%`} secondary='TOTAL ROI' />
                          </ListItemButton>
                      </ListItem>
  
                      <ListItem disablePadding>
                          <ListItemButton>
                          <ListItemIcon>
                              <AccessTimeIcon />
                          </ListItemIcon>
                          <ListItemText primary={plan.duration} secondary='CONTRACT DURATION' />
                          </ListItemButton>
                      </ListItem>
  
                      <ListItem disablePadding>
                          <ListItemButton>
                          <ListItemIcon>
                              <Diversity3Icon />
                          </ListItemIcon>
                          <ListItemText primary={parseFloat(plan.bonus_per_downline)+ '% BONUS'} secondary={`REFERRAL BONUS`} />
                          </ListItemButton>
                      </ListItem>
                  </List>
                          
              </CardContent>
  
              <CardActions sx={{justifyContent: 'center'}}>
              <Button onClick={()=> {
                handleClickOpen();
                  setPlanId(plan.id);
                  setSelectedPlan(plan);
                  setConvertedAmount(parseFloat(plan.amount_invested ))                
  
              }}  fullWidth={true} color='primary' size="large" sx={{ textTransform: 'capitalize' }} variant="contained">
                  {(isInvesting && planId === plan.id) ? 'Sending....' : 'Invest Now'}
              </Button>
              </CardActions>
  
          </Card>
          </Grid> 
        ))
        
      }
      </Grid>
        
      
      }


      
              
<Dialog
        sx={{zIndex: 40000}}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle sx={{textAlign: 'center'}}>{"FUND YOUR WALLET"}</DialogTitle> */}

        <DialogContent>
          {selectedPlan !== null &&
          <DialogContentText sx={{textAlign: 'center'}} mb={2} id="alert-dialog-slide-description">
          Kindly enter any amount from {selectedPlan !== null &&`
           ${formatPrice(selectedPlan.min_amount )} to  ${formatPrice(selectedPlan.amount_invested )} 
          `        
          }   to invest
           
          </DialogContentText>
          }
          

          <Box px={1} sx={{display: 'flex', justifyContent: 'space-evenly'}}>
            <FormControl  sx={{ m: 1, minWidth: '100%' }} size="small">
              <InputLabel htmlFor="amount">Enter Amount</InputLabel>
              <OutlinedInput
                onChange={(e)=>setAmount(e.target.value)}
                id="amount"
                type={'number'}
                label="Enter Amount"
              />
            </FormControl>
          </Box> 

          <Button onClick={postInvestment} fullWidth={true} size="large" color="primary" variant="contained"> 
            {isInvesting ? 'Sending...' : 'Submit Request'}  
          </Button>      
          
        </DialogContent>
        
      </Dialog>
    
</div>
  )
}

export default RealEstatePlan
