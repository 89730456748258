import React from 'react'
import CoinMarket from './CoinMarket';
import WalletCards from '../Wallet/WalletCrads';

const Index = () => {
  return (
    <>
    {/* <BalanceCard/> */}
    
    {/* <PyramidData/> */}
    {/* <MobileMenu/> */}
    <CoinMarket/>
    <WalletCards/>
    </>
  )
}

export default Index
